import PropTypes from 'prop-types';
import { useEffect } from 'react';
import * as Sentry from '@sentry/react';

import Head from 'next/head';
import Router from 'next/router';

// Import order here is important
import 'reset.css';
import 'normalize.css';
import 'global.scss';

import Preload from 'components/Preload';
import { googleAnalyticsEffect } from 'utils/google-analytics';

const dsn = 'https://40dec5d9b8774459b6486ecc785cf291@o39628.ingest.sentry.io/5398917';
const environment = 'production';
Sentry.init({ dsn, environment, normalizeDepth: 15 });

Router.events.on('routeChangeComplete', () => document.getElementById('__next').scrollTo(0, 0));

const App = ({ Component, pageProps }) => {
  useEffect(googleAnalyticsEffect, []);

  return (
    <>
      <Head>
        <title>MetLife Legal Plans</title>
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="stylesheet" href="/averta.css" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <Preload />
      <Component {...pageProps} />
    </>
  );
};

App.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
};

export default App;
